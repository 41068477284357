<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <BaseModal size="xl" title="Điều chỉnh phụ lục đề xuất chương trình xúc tiến đầu tư quốc gia"
               :show="isPhuLucModalOpen"
               @update:show="value => toggleModal({show:value})">
      <template #default>
        <div>
          <div class="w-100">
            <div class="pdf-wrapper">
              <div>
                <div class="">
                  <div class="pdf-page fs-16">
                    <div id="pdf1" class="pdf-content">
                      <div class="text-center fs-16">
                        <strong class="bold">Mẫu C.I.2</strong><br>
                        <strong>Biểu tổng hợp đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia hàng
                          năm</strong><br>
                        <p class="font-italic">(Áp dụng cho hồ sơ đề xuất thực hiện chương trình xúc tiến đầu tư quốc
                          gia quy định tại Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                        <hr>
                      </div>
                      <div class="row fs-16">
                        <div class="col-md-6">
                          <b class="text-uppercase">{{ detailItem.coQuanChuTri }}</b>
                        </div>
                        <div class="col-md-6">
                          <p class="font-weight font-italic" style="float: right; display: flex">
                          <span class="mt-2">{{ detailItem.diaDiem }}, ngày {{
                              getDay(detailItem.ngayVanBan)
                            }} tháng {{
                              getMonth(detailItem.ngayVanBan)
                            }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="text-center">
                        <b class="text-uppercase">Biểu tổng hợp đề xuất thực hiện chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(detailItem.ngayVanBan)) + 1 }}</b>
                        <p class="font-italic">(Kèm theo đề xuất thực hiện chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(detailItem.ngayVanBan)) + 1 }} của Bộ/UBND cấp tỉnh
                          {{ detailItem.coQuanChuTri }} tại
                          công văn số {{ detailItem.soVanBan }} ngày {{ getDay(detailItem.ngayVanBan) }} tháng {{
                            getMonth(item.ngayVanBan)
                          }} năm {{ getYear(detailItem.ngayVanBan) }} của {{ detailItem.coQuanChuTri }})</p>
                      </div>
                      <!--                      {{chiTietDeXuatCTXTDTQuocGia}}-->
                      <div class="float-right" style="display: flex">
                        <p>Đơn vị: triệu VNĐ</p>
                        <button class="btn btn-behance ml-3" @click="addHoatDong">Thêm hoạt động</button>
                      </div>
                      <div class="">
                        <vue-scrolling-table>
                          <template slot="thead" class="bg-secondary">
                            <tr class="bg-secondary">
                              <!--                              <th class="text-center align-middle fs-12 w-50px" rowspan="2">-->
                              <!--                                <i class="c-icon cil-options rotate90"></i>-->
                              <!--                              </th>-->
                              <th class="text-center align-middle fs-12 w-50px" rowspan="3">
                                STT
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Tên hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Loại hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Dự kiến thời gian tổ chức thực hiện
                                (tháng, năm) <span class="text-danger">*</span>
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Đơn vị chủ trì thực hiện <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" colspan="2">Địa điểm tổ chức <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Mục đích/Nội dung hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Ngành/Lĩnh vực kêu gọi đầu tư (ngành
                                ISIC cấp 2) <span class="text-danger">*</span>
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Căn cứ triển khai hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" colspan="4">Đơn vị phối hợp <span class="text-danger">*</span></th>
<!--                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Nguồn kinh phí</th>-->
                              <th class="p-1 text-center fs-13 w-120" colspan="4">Kinh phí <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="1" colspan="2">Đề án hoạt động XTĐT <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Dự toán kinh phí của hoạt động XTĐT đề
                                xuất <span class="text-danger">*</span>
                              </th>
                              <th class="p-1 text-center fs-13 w-90px" rowspan="3">Ghi chú</th>
                              <th class="p-1 text-center fs-13 w-50px" rowspan="3">Thao tác</th>
                            </tr>
                            <tr class="bg-secondary">
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Trong nước (địa phương)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Nước ngoài (quốc gia)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Chương trình xúc tiến đầu tư quốc
                                gia
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Ngân sách của Bộ/địa phương</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Khác (Xã hội hóa)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tổng kinh phí</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Nhập thông tin</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Nhập file</th>
                            </tr>
                            <!--                            <tr class="bg-secondary">-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Trong nước (tên doanh nghiệp)</th>-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Nước ngoài (tên doanh nghiệp)</th>-->
                            <!--                            </tr>-->
                          </template>
                          <template slot="tbody">
                            <template v-if="chiTietDeXuatCTXTDTQuocGia.length">
                              <tr v-for="(it, indx) in chiTietDeXuatCTXTDTQuocGia" v-bind:key="it.id">
                                <td class="text-center p-1 fs-13 align-middle w-50px">{{ indx + 1 }}</td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.tenHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.loaiHoatDongId">
                                    <option :value="itemz.value" v-for="itemz in optionsLoaiHoatDong"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <v-date-picker class="" v-model="it.duKienThoiGianToChuc" locale="vi">
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <input
                                        class="form-control"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.donViChuTriThucHien">
                                    <option :value="itemz.value" v-for="itemz in optionsDonViChuTriThucHien"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
<!--                                  <input type="text" class="form-control" v-if="it.donViChuTriThucHienSelect === 'Khác (ghi rõ)'"-->
<!--                                         placeholder=""-->
<!--                                         v-model="it.donViChuTriThucHien"/>-->
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaDiem_TrongNuocId">
                                    <option :value="itemz.value" v-for="itemz in optionsTinhThanh"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaDiem_NuocNgoaiId">
                                    <option :value="itemz.value" v-for="itemz in optionsQuocGia" v-bind:key="itemz.id">
                                      {{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.mucDich_NoiDungHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.nganhLinhVucKeuGoiDauTuId">
                                    <option :value="itemz.value" v-for="itemz in optionsNganhLinhVuc"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaBanKeuGoiDauTu">
                                    <option :value="itemz.value" v-for="itemz in optionsDiaBanKeuGoiDauTu"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.canCuTrienKhai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_NuocNgoai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.doanhNghiep_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control"
                                         placeholder=""
                                         v-model="it.doanhNghiep_NuocNgoai"/>
                                </td>
<!--                                <td class="text-center p-1 fs-13 align-middle w-120">-->
<!--                                  <select class="form-control" v-model="it.nguonKinhPhi">-->
<!--                                    <option :value="itemz.value" v-for="itemz in optionsNguoiKinhPhi"-->
<!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
<!--                                    </option>-->
<!--                                  </select>-->
<!--                                </td>-->
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input type="number" class="form-control"
                                         placeholder=""
                                         v-model="it.kinhPhi_ChuongTrinhXTDTQG" @input="it.kinhPhi_ChuongTrinhXTDTQG = Math.abs(it.kinhPhi_ChuongTrinhXTDTQG)"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input type="number" class="form-control"
                                         placeholder=""
                                         v-model="it.kinhPhi_NganSachDiaPhuong" @input="it.kinhPhi_NganSachDiaPhuong = Math.abs(it.kinhPhi_NganSachDiaPhuong)"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input type="number" class="form-control"
                                         placeholder=""
                                         v-model="it.kinhPhi_Khac" @input="it.kinhPhi_Khac = Math.abs(it.kinhPhi_Khac)"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  {{
                                    Number(it.kinhPhi_ChuongTrinhXTDTQG) + Number(it.kinhPhi_NganSachDiaPhuong) + Number(it.kinhPhi_Khac) | formatNumber
                                  }}
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <template v-if="it.daNopDeAn === undefined">
                                    <CButton class="" color="primary"
                                             @click="openDeAnUpdateModal(it)">
                                      <i name="cil-highlighter"/> Cập nhật đề án
                                    </CButton>
                                  </template>
                                  <template v-else>
                                    <CButton class="mt-3" color="primary" v-if="!it.daNopDeAn"
                                             @click="openDeAnCreateModal(it)">
                                      <i name="cil-highlighter"/> Nhập đề án
                                    </CButton>
                                    <span class="alert-primary" v-else>Đã nộp đề án</span>
                                  </template>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <FileUpload v-if="it.daNopDeAn === undefined" ref="upload"
                                              @onChange="changeFiles($event, indx)" :type="type"
                                              :attachments="JSON.parse(it.dinhKem_DeAnHoatDong)"/>
                                  <FileUpload v-else ref="upload" @onChange="changeFiles($event, indx)" :type="type"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <FileUpload v-if="it.daNopDeAn === undefined" ref="upload2"
                                              @onChange="changeFiles2($event, indx)" :type="type"
                                              :attachments="JSON.parse(it.dinhKem_DuToanKinhPhi)"/>
                                  <FileUpload v-else ref="upload2" @onChange="changeFiles2($event, indx)" :type="type"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-90px">
                                  <span v-if="it.trangThai === 1">Ban đầu</span>
                                  <span v-if="it.trangThai === 2">Điều chỉnh</span>
                                  <span v-if="it.trangThai === 3">Bổ sung</span>
                                  <span v-if="it.trangThai === 4">Hủy bỏ</span>
                                  <span v-if="!it.trangThai">Mới</span>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-50px">
                                  <CButton size="sm" color="danger" @click="deleteHoatDong(indx)">
                                    <CIcon name="cil-x"/> Xóa
                                  </CButton>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td class="text-center p-1 fs-13 align-middle" colspan="24">Chưa có dữ liệu</td>
                              </tr>
                            </template>
                          </template>
                        </vue-scrolling-table>
                      </div>
                      <div class="col-12 row mt-5">
                        <div class="col-5"></div>
                        <div class="col-7 text-center">
                          <b>Người lập biểu</b>
                          <br>
                          <br>
                          <br>
                          <br>
                          <br>
                          <p class="font-italic">(Ghi rõ họ tên, đơn vị, ký, điện thoại, email)</p>
                        </div>
                      </div>
                      <div class="col-12 mt-5">
                        <p class="text-danger">Lưu ý: cột dấu sao là cột bắt buộc</p>
                      </div>
                      <div class="col-12 mt-3">
                        <button type="button" class="btn btn-primary" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">
                          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu
                          lại
                        </button>
                        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
                      </div>
                      <!--                      {{ chiTietDeXuatCTXTDTQuocGia }}-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeXuatCTXTDTQGDeAnUpdateModal2 :item="item" :it="itemProp" @onSubmit="emitDataDeAn($event)"/>
        <DeXuatCTXTDTQGDeAnCreateModal :item="item" :it="itemProp" @onSubmit="emitDataDeAn($event)"/>
      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu
          lại
        </button>
        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import VueScrollingTable from 'vue-scrolling-table'
import moment from 'moment'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
// import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { enums } from '@/shared/enums'
import DeXuatCTXTDTQGDeAnUpdateModal2 from './DeXuatCTXTDTQGDeAnUpdateModal2'
import appConfig from '@/shared/appConfig'
import FileUpload from '@/components/file-upload/FileUpload'
import { v4 as uuidv4 } from 'uuid'
import DeXuatCTXTDTQGDeAnCreateModal from './DeXuatCTXTDTQGDeAnCreateModal'
import { TOGGLE_CREATE_DEAN_MODAL } from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'

export default {
  name: 'DeXuatCTXTDTQGPhuLucUpdateModal2',
  props: ['item'],
  components: {
    VueScrollingTable,
    DeXuatCTXTDTQGDeAnUpdateModal2,
    FileUpload,
    DeXuatCTXTDTQGDeAnCreateModal
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTQuocGia',
      isLoading: false,
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: [],
      optionsDonViChuTriThucHien: [],
      chiTietDeXuatCTXTDTQuocGia: [],
      detailItem: {
        chiTietDeXuatCTXTDTQuocGia: []
      },
      itemProp: {},
      deAnProp: {},
      itemEmit: {}
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTQG', {
      // item: 'detailItem',
      isSaving: 'isSavingPhuLuc2',
      isPhuLucModalOpen: 'isPhuLucUpdateModalOpen'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG',
      {
        toggleModal: types.TOGGLE_UPDATE_PHULUC_MODAL,
        toggleUpdateDeAnModal: types.TOGGLE_UPDATE_DEAN_MODAL,
        createItem: types.CREATE_CTXTDTQGPHULUC,
        updateItem: types.UPDATE_CTXTDTQGPHULUC,
        toggleCreateDeAnModal: TOGGLE_CREATE_DEAN_MODAL
      }),
    async saveItem () {
      // const validate = await this.$refs.form.validate()
      // console.log(validate)
      // if (validate) {
      let validateAttachments = false
      const array = []
      for (const i in this.chiTietDeXuatCTXTDTQuocGia) {
        // this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG = Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG)
        // this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong = Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong)
        // this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac = Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac)
        if (!JSON.parse(this.chiTietDeXuatCTXTDTQuocGia[i].dinhKem_DeAnHoatDong) || !JSON.parse(this.chiTietDeXuatCTXTDTQuocGia[i].dinhKem_DuToanKinhPhi)) validateAttachments = true
        // if (this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect !== 'Khác (ghi rõ)') {
        //   this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHien = this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect
        // }
        const item = {
          id: this.chiTietDeXuatCTXTDTQuocGia[i].id,
          deXuatCTXTDTQuocGiaGuid: this.chiTietDeXuatCTXTDTQuocGia[i].deXuatCTXTDTQuocGiaGuid,
          guid: this.chiTietDeXuatCTXTDTQuocGia[i].guid,
          tenHoatDong: this.chiTietDeXuatCTXTDTQuocGia[i].tenHoatDong,
          loaiHoatDongId: Number(this.chiTietDeXuatCTXTDTQuocGia[i].loaiHoatDongId),
          duKienThoiGianToChuc: this.chiTietDeXuatCTXTDTQuocGia[i].duKienThoiGianToChuc,
          donViChuTriThucHien: this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHien,
          diaDiem_TrongNuocId: Number(this.chiTietDeXuatCTXTDTQuocGia[i].diaDiem_TrongNuocId),
          diaDiem_NuocNgoaiId: Number(this.chiTietDeXuatCTXTDTQuocGia[i].diaDiem_NuocNgoaiId),
          mucDich_NoiDungHoatDong: this.chiTietDeXuatCTXTDTQuocGia[i].mucDich_NoiDungHoatDong,
          nganhLinhVucKeuGoiDauTuId: Number(this.chiTietDeXuatCTXTDTQuocGia[i].nganhLinhVucKeuGoiDauTuId),
          diaBanKeuGoiDauTu: this.chiTietDeXuatCTXTDTQuocGia[i].diaBanKeuGoiDauTu,
          canCuTrienKhai: this.chiTietDeXuatCTXTDTQuocGia[i].canCuTrienKhai,
          toChucCoQuan_TrongNuoc: this.chiTietDeXuatCTXTDTQuocGia[i].toChucCoQuan_TrongNuoc,
          toChucCoQuan_NuocNgoai: this.chiTietDeXuatCTXTDTQuocGia[i].toChucCoQuan_NuocNgoai,
          doanhNghiep_TrongNuoc: this.chiTietDeXuatCTXTDTQuocGia[i].doanhNghiep_TrongNuoc,
          doanhNghiep_NuocNgoai: this.chiTietDeXuatCTXTDTQuocGia[i].doanhNghiep_NuocNgoai,
          nguonKinhPhi: this.chiTietDeXuatCTXTDTQuocGia[i].nguonKinhPhi,
          kinhPhi_ChuongTrinhXTDTQG: Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_ChuongTrinhXTDTQG),
          kinhPhi_NganSachDiaPhuong: Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_NganSachDiaPhuong),
          kinhPhi_Khac: Number(this.chiTietDeXuatCTXTDTQuocGia[i].kinhPhi_Khac),
          deAnHoatDongXTDTQuocGiaGuid: this.chiTietDeXuatCTXTDTQuocGia[i].deAnHoatDongXTDTQuocGiaGuid,
          dinhKem_DeAnHoatDong: this.chiTietDeXuatCTXTDTQuocGia[i].dinhKem_DeAnHoatDong,
          dinhKem_DuToanKinhPhi: this.chiTietDeXuatCTXTDTQuocGia[i].dinhKem_DuToanKinhPhi
        }
        array.push(item)
      }
      this.chiTietDeXuatCTXTDTQuocGia = array
      // if (this.editedItem.deXuatCTXTDTQuocGiaGuid) {
      //   this.editedItem.chiTietDeXuatCTXTDTQuocGia = this.chiTietDeXuatCTXTDTQuocGia
      //   await this.updateItem(this.editedItem)
      // } else {
      if (!validateAttachments) {
        const data = {
          deXuatCTXTDTQuocGiaGuid: this.item.guid,
          chiTietDeXuatCTXTDTQuocGia: this.chiTietDeXuatCTXTDTQuocGia,
          id: this.item.id
        }
        // await this.createItem(data)
        // }
        // if (this.responseResult.error) {
        //   this.$toast.error(this.responseResult.message)
        // } else {
        //   this.$toast.success(this.responseResult.message)
        this.toggleModal({ show: false })
        this.$emit('onSubmit', data)
        // }
        // }
      } else {
        this.$toast.error('Tệp đính kèm Đề án hoạt động hoặc đính kèm Dự toán kinh phí hiện đang bị thiếu!')
      }
    },
    openDeAnCreateModal (data) {
      // if (data.donViChuTriThucHienSelect !== 'Khác (nêu rõ)') {
      //   data.donViChuTriThucHien = data.donViChuTriThucHienSelect
      // }
      this.itemProp = data
      // this.itemProp.NganhLinhVucKeuGoiDauTu = this.optionsNganhLinhVuc.find(x => x.value === data.nganhLinhVucKeuGoiDauTuId)
      // this.itemProp.loaiHoatDongId = this.optionsLoaiHoatDong.find(x => x.value === data.loaiHoatDongId)
      this.toggleCreateDeAnModal({ show: true })
    },
    openDeAnUpdateModal (data) {
      // if (data.donViChuTriThucHienSelect) {
      //   if (data.donViChuTriThucHienSelect !== 'Khác (nêu rõ)') {
      //     data.donViChuTriThucHien = data.donViChuTriThucHienSelect
      //   }
      // }
      this.itemProp = data
      this.toggleUpdateDeAnModal({ show: true })
    },
    emitDataDeAn (data) {
      this.itemEmit = data
      console.log('Đề án emit create: ', data)
      for (const i in this.chiTietDeXuatCTXTDTQuocGia) {
        if (this.chiTietDeXuatCTXTDTQuocGia[i].guid === data.chiTietDeXuatCTXTDTQuocGiaGuid) {
          this.chiTietDeXuatCTXTDTQuocGia[i].daNopDeAn = true
          this.chiTietDeXuatCTXTDTQuocGia[i].deAnHoatDongXTDTQuocGiaGuid = data.guid
          break
        }
      }
    },
    changeFiles (data, index) {
      this.chiTietDeXuatCTXTDTQuocGia[index].dinhKem_DeAnHoatDong = JSON.stringify(data.ListFileAttachAdd)
      // this.Attachments = data.ListFileAttachAdd
    },
    changeFiles2 (data, index) {
      this.chiTietDeXuatCTXTDTQuocGia[index].dinhKem_DuToanKinhPhi = JSON.stringify(data.ListFileAttachAdd)
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    addHoatDong () {
      const item = {
        id: 0,
        deXuatCTXTDTQuocGiaGuid: this.item.guid,
        guid: uuidv4(),
        tenHoatDong: '',
        loaiHoatDongId: null,
        duKienThoiGianToChuc: '',
        // donViChuTriThucHienSelect: '',
        donViChuTriThucHien: '',
        diaDiem_TrongNuocId: null,
        diaDiem_NuocNgoaiId: null,
        mucDich_NoiDungHoatDong: '',
        nganhLinhVucKeuGoiDauTuId: null,
        diaBanKeuGoiDauTu: null,
        canCuTrienKhai: '',
        toChucCoQuan_TrongNuoc: '',
        toChucCoQuan_NuocNgoai: '',
        doanhNghiep_TrongNuoc: '',
        doanhNghiep_NuocNgoai: '',
        nguonKinhPhi: 0,
        kinhPhi_ChuongTrinhXTDTQG: 0,
        kinhPhi_NganSachDiaPhuong: 0,
        kinhPhi_Khac: 0,
        deAnHoatDongXTDTQuocGiaGuid: '',
        dinhKem_DeAnHoatDong: '',
        dinhKem_DuToanKinhPhi: '',
        daNopDeAn: false
      }
      this.chiTietDeXuatCTXTDTQuocGia.push(item)
      // console.log(this.chiTietDeXuatCTXTDTQuocGia.length)
    },
    deleteHoatDong (index) {
      if (confirm('Bạn có chắc chắn muốn xóa không?')) {
        this.chiTietDeXuatCTXTDTQuocGia.splice(index, 1)
      }
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    // this.optionsLoaiHoatDong = enums.loaiHoatDongDeXuatCTXTDT
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    // this.optionsDiaBanKeuGoiDauTu = enums.diaBanKeuGoiDauTuDeXuatCTXTDT
    this.optionsDonViChuTriThucHien = enums.donViChuTriDeXuatCTXTDT
    this.optionsDiaBanKeuGoiDauTu = await vungKinhTeService.getAll()
  },
  watch: {
    item: function (val) {
      this.detailItem = val
      this.chiTietDeXuatCTXTDTQuocGia = val.chiTietDeXuatCTXTDTQuocGia
      // for (const i in this.chiTietDeXuatCTXTDTQuocGia) {
      //   switch (this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHien) {
      //     case '--Chọn--': {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = '--Chọn--'
      //       break
      //     }
      //     case 'Ủy ban nhân dân tỉnh/thành phố': {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = 'Ủy ban nhân dân tỉnh/thành phố'
      //       break
      //     }
      //     case 'Sở Kế hoạch và Đầu tư': {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = 'Sở Kế hoạch và Đầu tư'
      //       break
      //     }
      //     case 'Ban quản lý KCN - KCX': {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = 'Ban quản lý KCN - KCX'
      //       break
      //     }
      //     case 'Trung tâm XTĐT địa phương': {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = 'Trung tâm XTĐT địa phương'
      //       break
      //     }
      //     default : {
      //       this.chiTietDeXuatCTXTDTQuocGia[i].donViChuTriThucHienSelect = 'Khác (ghi rõ)'
      //       break
      //     }
      //   }
      // }
      // console.log(val)
    }
  }
}
</script>

<style scoped>

</style>
